import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="featuer-page-article"
export default class extends Controller {
  static targets = [
    "unreadCheckboxDiv",
    "unreadCheckboxLabel",
    "unreadCheckboxInput",
  ];

  declare readonly unreadCheckboxDivTarget: HTMLElement;
  declare readonly unreadCheckboxLabelTarget: HTMLElement;
  declare readonly unreadCheckboxInputTarget: HTMLInputElement;

  // 未読の記事のみ表示をクリックした際に実行される関数
  onClickUnreadCheck(e: Event): void {
    e.preventDefault();
    const currentUrl = new URL(window.location.href);
    const filterParams = new URLSearchParams(currentUrl.search);

    // 未読かどうかのチェックボックスを切り替える。
    this.unreadCheckboxInputTarget.checked = !this.unreadCheckboxInputTarget.checked;

    // この処理自体はなくても良いが、ページの読み込み完了までの見た目がスムーズになるようにしている。
    if (this.unreadCheckboxInputTarget.checked) {
      this.unreadCheckboxDivTarget.classList.add('is_current');
      this.unreadCheckboxLabelTarget.classList.add('is_current');
    } else {
      this.unreadCheckboxDivTarget.classList.remove('is_current');
      this.unreadCheckboxLabelTarget.classList.remove('is_current');
    }

    // パラメータをセット
    filterParams.set('unread', `${this.unreadCheckboxInputTarget.checked}`);
    // パラメータのpageをリセット
    filterParams.delete('page')
    window.location.href = currentUrl.pathname + '?' + filterParams.toString();
  }
}
